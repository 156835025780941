<template>
  <div>
    <v-card-text>
      <apexchart :options="options" :series="series" height="280px" />
    </v-card-text>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "PowerSummaryChart",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    pdu: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      series: [
        {
          name: this.$i18n.t("max"),
          data: this.pdu.summary.map(function (sum) {
            return {
              x: sum.month,
              y: sum.maximum === null ? 0 : sum.maximum.toFixed(2),
            };
          }),
        },
        {
          name: this.$i18n.t("avg"),
          data: this.pdu.summary.map(function (sum) {
            return {
              x: sum.month,
              y: sum.average === null ? 0 : sum.average.toFixed(2),
            };
          }),
        },
        {
          name: this.$i18n.t("kWh"),
          data: this.pdu.summary.map(function (sum) {
            return {
              x: sum.month,
              y:
                sum.kilowatt_hours === null ? 0 : sum.kilowatt_hours.toFixed(2),
            };
          }),
        },
      ],
    };
  },
  computed: {
    options() {
      this.$vuetify.theme.dark; // force re-compute

      return {
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        title: {
          text: this.pdu.name,
          align: "center",
          offsetY: 20,
          style: {
            fontSize: "14px",
          },
        },
        chart: {
          background: this.$vuetify.theme.dark ? "#1E1E1E" : "#FFF",
          type: "line",
          height: 380,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
            tools: {
              download: false,
            },
          },
        },
        stroke: {
          curve: "smooth",
          width: 3,
        },
        fill: {
          type: "gradient",
          gradient: {
            opacityFrom: 0.6,
            opacityTo: 0.8,
          },
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          onItemClick: {
            toggleDataSeries: true,
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            datetimeUTC: false,
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM",
            },
          },
        },
      };
    },
  },
};
</script>

<i18n>
{
  "en": {
    "avg": "power &#8960; (W)",
    "max": "power max (W)",
    "kWh": "power consumption (kWh)"
  },
  "de": {
    "avg": "Leistung &#8960; (W)",
    "max": "Leistung max (W)",
    "kWh": "Stromverbrauch (kWh)"
  }
}
</i18n>

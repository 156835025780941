<template>
  <v-card>
    <v-card-text>
      <data-table-extended
        :headers="headers"
        :items="racks"
        item-key="id"
        sort-by="rack"
        :loading="loading"
        :hide-default-footer="racks.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }"
        show-expand
        :show-menu="false"
        open-expand-by-click-row
      >
        <template v-slot:item.rack="{ item }">
          {{ $t("rack") }} {{ item.rack }}
        </template>

        <template v-slot:item.pdu="{ item }">
          {{ item.pdu.map((pdu) => pdu.serial).join(", ") }}
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <power-rack :rack="item.rack" :pdus="item.pdu" class="my-4" />
          </td>
        </template>
      </data-table-extended>
    </v-card-text>
  </v-card>
</template>

<script>
import DataTableExtended from "@/components/basics/DataTableExtended.vue";
import PowerRack from "@/components/services/PowerRack";

export default {
  name: "Power",
  components: {
    DataTableExtended,
    PowerRack,
  },
  data: () => ({
    racks: [],
    loading: false,
  }),
  watch: {
    "$store.state.session": function () {
      this.getHardware();
    },
  },
  computed: {
    headers() {
      return [
        { text: this.$i18n.t("rack"), value: "rack" },
        { text: this.$i18n.t("pdu"), value: "pdu" },
        { text: "", value: "data-table-expand" },
      ];
    },
  },
  methods: {
    getHardware: function () {
      var that = this;
      this.loading = true;
      this.$http
        .get("services/power/hardware")
        .then((response) => {
          /* create a unique rack list */
          let racks = {};
          let id = 0;
          response.data.forEach((item) => {
            let name = item["rack"];
            if (racks[name] == null) {
              id += 1;
              racks[name] = {
                id: id,
                rack: name,
                pdu: [],
              };
            }
            racks[name].pdu.push(item);
          });
          this.racks = Object.keys(racks).map((name) => racks[name]);
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  mounted() {
    this.getHardware();
  },
};
</script>

<i18n>
{
  "en": {
    "rack": "Rack",
    "pdu": "PDU"
  }, 
  "de": {
    "rack": "Rack",
    "pdu": "PDU"
  }
}
</i18n>

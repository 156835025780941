<template>
  <v-card outlined>
    <v-progress-linear :active="loading" indeterminate />
    <v-toolbar class="mt-4" flat dense>
      <v-toolbar-title>{{ $t("details") }} {{ rack }}</v-toolbar-title>
      <v-spacer />
      <date-picker
        v-model="dates"
        :allowedDates="dateAllowed"
        icon
        range
        sort-range
        left
        bottom
        rounded
      />
      <v-menu left bottom rounded>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-vector-arrange-above</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item-group v-model="interval" color="primary">
            <v-list-item
              v-for="(choice, i) in intervalChoices"
              :key="i"
              :disabled="i == interval"
            >
              <v-list-item-title>
                {{ $t(choice) }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-toolbar>
    <div v-for="(serie, name, index) in series" :key="index">
      <v-card-title>{{ $t(name) }}</v-card-title>
      <v-card-text>
        <apexchart
          :options="serie.options"
          :series="serie.series"
          height="360px"
          class="flex-grow-1"
        />
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import downloadFile from "@/utils/mixins/downloadFile";
import DatePicker from "@/components/basics/DatePicker";
import formatNumber from "@/utils/mixins/formatNumber";
import formatDate from "@/utils/mixins/formatDate";
import formatDateTime from "@/utils/mixins/formatDateTime";
import VueApexCharts from "vue-apexcharts";

var moment = require("moment");

export default {
  name: "PowerDetailsChart",
  components: {
    DatePicker,
    apexchart: VueApexCharts,
  },
  props: {
    rack: {
      type: String,
      required: true,
    },
  },
  mixins: [downloadFile, formatNumber, formatDate, formatDateTime],
  data: () => ({
    showEnergyGraph: false,
    interval: 1,
    intervalChoices: ["5min", "hourly", "daily"],
    series: {},
    summaryEnergy: 0.0,
    summaryEnergyUnit: null,
    loading: false,
    loadingPdf: false,
    dates: [
      moment(new Date()).subtract(14, "days").format("YYYY-MM-DD"),
      moment(new Date()).format("YYYY-MM-DD"),
    ],
    baseOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
        stacked: false,
        type: "line",
      },
      fill: {
        type: "gradient",
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.8,
        },
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
        onItemClick: {
          toggleDataSeries: true,
        },
      },
      xaxis: {
        type: "datetime",
        labels: {
          datetimeUTC: false,
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
    },
  }),
  watch: {
    "$vuetify.theme.dark": {
      immediate: true,
      handler(value) {
        this.baseOptions = {
          ...this.baseOptions,
          tooltip: {
            theme: value ? "dark" : "light",
          },
          noData: {
            text: this.$i18n.t("no data"),
          },
        };
      },
    },
    rack() {
      this.getData();
    },
    dates(value) {
      if (value && value.length == 2) {
        this.getData();
      }
    },
    interval(value) {
      this.getData();
    },
  },
  methods: {
    dateAllowed(date) {
      const today = this.$moment(new Date()).format("YYYY-MM-DD");
      return (
        this.$moment(today).isSame(date, "day") ||
        this.$moment(date).isBefore(today)
      );
    },
    getData() {
      if (!this.loading) {
        var that = this;
        var aggregate = this.intervalChoices[this.interval];
        this.loading = true;
        this.$http
          .get("services/power/data", {
            params: {
              rack: this.rack,
              aggregate: aggregate,
              date_after:
                this.dates.length > 0 ? this.dates[0] + " 00:00:00" : null,
              date_before:
                this.dates.length > 1 ? this.dates[1] + " 23:59:59" : null,
            },
          })
          .then((response) => {
            that.summaryEnergy = 0;
            that.series = {};
            response.data.forEach(function (serie) {
              // initialize serie
              if (!that.series.hasOwnProperty(serie.type)) {
                that.series[serie.type] = {
                  options: {
                    ...that.baseOptions,
                    yaxis: {
                      forceNiceScale: true,
                      title: {
                        text: that.$i18n.t(serie.type + "_unit"),
                      },
                    },
                  },
                  series: [],
                };
              }

              if (aggregate == "5min") {
                that.series[serie.type].series.push({
                  name: "PDU " + serie.pdu,
                  data: serie.data.map(function (e) {
                    return {
                      y: Number(e[0] || 0).toFixed(3),
                      x: e[1],
                    };
                  }),
                });
              } else {
                that.series[serie.type].series.push({
                  name: "PDU " + serie.pdu + " " + that.$i18n.t("max"),
                  data: serie.data.map(function (e) {
                    return {
                      y: Number(e[0] || 0).toFixed(3),
                      x: e[2],
                    };
                  }),
                });
                that.series[serie.type].series.push({
                  name: "PDU " + serie.pdu + " " + that.$i18n.t("avg"),
                  data: serie.data.map(function (e) {
                    return {
                      y: Number(e[1] || 0).toFixed(3),
                      x: e[2],
                    };
                  }),
                });
              }
            });
          })
          .catch((err) => {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          })
          .finally(function () {
            that.loading = false;
          });
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<i18n scoped>
{
  "en": {
   "details": "Details",
   "max": "max",
   "avg": "&#8960;",
   "select interval": "select interval",
   "download stats as PDF": "download stats as PDF",
   "amp": "ampere",
   "powers": "volt-ampere",
   "powerp": "power",
   "volt": "voltage",
   "amp_unit": "A",
   "powers_unit": "VA",
   "powerp_unit": "W",
   "volt_unit": "V",
   "5min": "5 minutes samples",
   "hourly": "60 minutes aggregates",
   "daily": "24 hours aggregates",
   "no data": "no data"
  },
  "de": {
  "details": "Details",
   "max": "max",
   "avg": "&#8960;",
   "select interval": "Interval auswählen",
   "amp": "Stromstärke",
   "powers": "Scheinleistung",
   "powerp": "Leistung",
   "volt": "Spannung",
   "amp_unit": "A",
   "powers_unit": "VA",
   "powerp_unit": "W",
   "volt_unit": "V",
   "5mi": "5 Minutes Samples",
   "hourly": "60 Minuten Aggregate",
   "daily": "24 Stunden Aggregate",
   "no data": "keine Daten"
  }
}
</i18n>

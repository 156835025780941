<template>
  <div>
    <v-card outlined class="mb-4">
      <v-card-title> {{ $t("monthly summaries") }} {{ rack }} </v-card-title>
      <power-summary-chart v-for="(pdu, idx) in pdus" :pdu="pdu" :key="idx" />
    </v-card>
    <power-details-chart :rack="rack" />
  </div>
</template>

<script>
import PowerSummaryChart from "@/components/services/PowerSummaryChart";
import PowerDetailsChart from "@/components/services/PowerDetailsChart";

export default {
  name: "PowerRack",
  components: {
    PowerSummaryChart,
    PowerDetailsChart,
  },
  props: {
    rack: {
      type: String,
      required: true,
    },
    pdus: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    tabs: null,
  }),
};
</script>

<i18n>
{
  "en": {
    "monthly summaries": "Monthly Summaries"
  },
  "de": {
    "monthly summaries": "Monatsübersicht"
  }
}
</i18n>
